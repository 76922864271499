<template>
  <main class="d-flex w-100">
    <div class="container d-flex flex-column">
      <div class="row mt-5">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100 mt-5">
          <div class="d-table-cell">
            <div class="text-center mt-5">
              <div class="card">
                <div class="card-body">
                  <h3 class="card-title">Cambia la tua password</h3>
                  <p class="text-center fs-6" v-if="!isSubmitted">
                    Inserisci la tua vecchia password e poi la nuova, ricorda:
                    le due password devono essere diverse.
                  </p>
                  <div
                    :class="[
                      'alert',
                      submitResult ? 'alert-success' : 'alert-danger',
                    ]"
                    v-if="isSubmitted"
                  >
                    {{ axiosMessage }}
                  </div>
                  <el-form @submit.prevent="onSubmit" label-position="top">
                    <el-tooltip
                      v-model="capsTooltipOldPassword"
                      content="Caps lock is On"
                      placement="right"
                      manual
                    >
                      <el-form-item
                        prop="oldPassword"
                        label="Vecchia Password"
                        :error="vecchiaPasswordField.errorMessage"
                      >
                        <el-input
                          :type="oldPasswordType"
                          ref="oldPassword"
                          :model-value="vecchiaPasswordField.value"
                          @update:model-value="
                            vecchiaPasswordField.handleChange
                          "
                          placeholder="Vecchia Password"
                          name="oldPassword"
                          @keyup="checkCapslockOldPassword"
                          @blur="capsTooltipOldPassword = false"
                        /><span class="show-pwd" @click="showOldPwd">
                          <font-awesome-icon
                            :icon="
                              oldPasswordType === 'password' ? eye : eyeClosed
                            "
                          />
                        </span>
                      </el-form-item>
                    </el-tooltip>
                    <el-tooltip
                      v-model="capsTooltipPassword"
                      content="Caps lock is On"
                      placement="right"
                      manual
                    >
                      <el-form-item
                        prop="newPassword"
                        label="Password"
                        :error="passwordField.errorMessage"
                      >
                        <el-input
                          :key="passwordType"
                          ref="newPassword"
                          @update:model-value="passwordField.handleChange"
                          :model-value="passwordField.value"
                          :type="passwordType"
                          placeholder="Password"
                          name="newPassword"
                          @keyup="checkCapslockPassword"
                          @blur="capsTooltipPassword = false"
                        />
                        <span class="show-pwd" @click="showPwd">
                          <font-awesome-icon
                            :icon="
                              passwordType === 'password' ? eye : eyeClosed
                            "
                          />
                        </span>
                      </el-form-item>
                    </el-tooltip>
                    <el-tooltip
                      v-model="capsTooltipConfirmPassword"
                      content="Caps lock is On"
                      placement="right"
                      manual
                    >
                      <el-form-item
                        prop="newPasswordConfirm"
                        label="Conferma Password"
                        :error="passwordConfirmField.errorMessage"
                      >
                        <el-input
                          :key="confirmPasswordType"
                          ref="newPasswordConfirm"
                          @update:model-value="
                            passwordConfirmField.handleChange
                          "
                          :model-value="passwordConfirmField.value"
                          :type="confirmPasswordType"
                          placeholder="Conferma Password"
                          name="newPasswordConfirm"
                          @keyup="checkCapslockConfirmPassword"
                          @blur="capsTooltipConfirmPassword = false"
                        />
                        <span class="show-pwd" @click="showConfirmPwd">
                          <font-awesome-icon
                            :icon="
                              confirmPasswordType === 'password'
                                ? eye
                                : eyeClosed
                            "
                          />
                        </span>
                      </el-form-item>
                    </el-tooltip>
                    <el-button
                      :loading="loading"
                      type="primary"
                      native-type="submit"
                      style="width: 100%; margin-bottom: 30px"
                      >Cambia Password</el-button
                    >
                  </el-form>
                  <div v-if="submitResult">
                    <el-button type="text" @click="returnToLogin"
                      >Torna al login</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
import { ChangePassword, Logout } from "../../api/login.js";
export default {
  name: "LoginForm",
  components: { FontAwesomeIcon },

  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();

    const vecchiaPasswordField = reactive(
      useField("oldPassword", yup.string().required("Campo richiesto"))
    );
    const passwordField = reactive(
      useField(
        "newPassword",
        yup
          .string()
          .required("Campo richiesto")
          .min(8, "Minimo 8 caratteri")
          .test(
            "passwords-not-match",
            "La nuova password deve essere diversa dalla precedente",
            function (value) {
              return vecchiaPasswordField.value != value;
            }
          )
      )
    );
    const passwordConfirmField = reactive(
      useField(
        "newPasswordConfirm",
        yup
          .string()
          .required("Campo richiesto")
          .test(
            "passwords-match",
            "Le password devono corrispondere",
            function (value) {
              return passwordField.value === value;
            }
          )
      )
    );
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      vecchiaPasswordField,
      passwordField,
      passwordConfirmField,
    };
  },

  data() {
    return {
      isSubmitted: false,
      submitResult: false,
      onSubmit: this.handleSubmit(this.handleChangePassword),
      axiosErrorMessage: "",
      eye: faEye,
      eyeClosed: faEyeSlash,
      passwordType: "password",
      confirmPasswordType: "password",
      oldPasswordType: "password",
      capsTooltipOldPassword: false,
      capsTooltipPassword: false,
      capsTooltipConfirmPassword: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      axiosMessage: "",
    };
  },

  methods: {
    async returnToLogin() {
      await Logout().then(() => {
        this.$store.dispatch("user/logout").then(() => {
          this.$router.push("/");
        });
      });
    },
    checkCapslockOldPassword(e) {
      const { key } = e;
      this.capsTooltipOldPassword =
        key && key.length === 1 && key >= "A" && key <= "Z";
    },
    checkCapslockPassword(e) {
      const { key } = e;
      this.capsTooltipPassword =
        key && key.length === 1 && key >= "A" && key <= "Z";
    },
    checkCapslockConfirmPassword(e) {
      const { key } = e;
      this.capsTooltipConfirmPassword =
        key && key.length === 1 && key >= "A" && key <= "Z";
    },

    showOldPwd() {
      if (this.oldPasswordType === "password") {
        this.oldPasswordType = "";
      } else {
        this.oldPasswordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.oldPassword.focus();
      });
    },
    showConfirmPwd() {
      if (this.confirmPasswordType === "password") {
        this.confirmPasswordType = "";
      } else {
        this.confirmPasswordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.newPasswordConfirm.focus();
      });
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.newPassword.focus();
      });
    },
    async handleChangePassword(formValues) {
      this.loading = true;
      await ChangePassword(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosMessage = "Cambio della password avvenuto con successo!!";
        })

        .catch(() => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosMessage = "Cambio della password fallito";
        });

      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.show-pwd {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 22px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>